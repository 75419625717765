import React from 'react';
import './GenericTopBar.css';

interface Props {
  sticky: boolean;
  leftChildren: React.ReactNode;
  middleChildren: React.ReactNode;
  rightChildren: React.ReactNode;
}

/**
 * This is a glorified row container intended to be put in the top of pages.
 * It will verticaly center content and horizontally space them evenly.
 * @returns
 */
function GenericTopBar({
  sticky,
  leftChildren,
  middleChildren,
  rightChildren,
}: Props): JSX.Element {
  return (
    <div
      className={`GenericTopBarContainer ${sticky && 'GenericTopBarContainerSticky'}`}
    >
      <div>{leftChildren}</div>
      <div>{middleChildren}</div>
      <div>{rightChildren}</div>
    </div>
  );
}

export default GenericTopBar;
