import React, { ChangeEvent, useEffect, useState } from 'react';
import './BulkUpload.page.css';
import '../../ui/Shared.css';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import LargeFormPanel from '../../ui/panels/Forms/LargeFormPanel';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import SidePanelScaffold from '../../ui/pageScaffolds/SidePanelScaffold';
import AdminSideBarContent from '../../ui/panels/SideBars/AdminSideBarContent';
import MainLogo from '../../ui/components/MainLogo';

interface CSVMetadata {
  fileName: string;
  title: string;
  url: string | null;
  embedLink: string | null;
}

interface FileData {
  file: File; // The raw File object
  name: string; // Same as file.name, stored for convenience
  title: string;
  url: string | null;
  embedLink: string | null;
  networkResult: string | null;
  loading: boolean;
}

interface EntityInfo {
  entityId: string;
  entityName: string;
}

interface PresignedUrlResponse {
  presignedUrl: string;
  jobId: number;
}

function BulkUploadPage(): JSX.Element {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('token')
  );
  const [refreshToken, setRefreshToken] = useState<boolean>(false); // for 401 re-login
  const nav = useNavigate();

  // CSV data extracted from the single CSV file, if any
  const [csvData, setCsvData] = useState<CSVMetadata[] | null>(null);

  // The main list of txt/mp3 files (merged with CSV info)
  const [mergedFiles, setMergedFiles] = useState<FileData[]>([]);
  const [fileIndex, setFileIndex] = useState(0);
  const [uploading, setUploading] = useState<boolean>(false);

  // Not currently used but available if you want to separate completed / error files
  const [completedFiles, setCompletedFiles] = useState<FileData[]>([]);
  const [errorFiles, setErrorFiles] = useState<FileData[]>([]);

  // Entity handling
  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);
  const [entityInfo, setEntityInfo] = useState<EntityInfo[]>([]);
  const [loadingEntitiesError, setLoadingEntitiesError] =
    useState<boolean>(false);
  const [selectedEntity, setSelectedEntity] = useState<EntityInfo | null>(null);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  /**
   * Example: request entity data from your backend
   */
  const requestEntityData = async () => {
    const url = `/admin/entities`;
    try {
      setLoadingEntities(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          nav('/signin');
        }
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const jsonResponse = await response.json();
      const info: EntityInfo[] = JSON.parse(jsonResponse) as EntityInfo[];

      if (info.length === 0) {
        console.log(`Are you an admin?`);
        throw Error(`Admin is admin of 0 entities`);
      }

      setEntityInfo(info);
      setSelectedEntity(info[0]);
      setLoadingEntities(false);
      setLoadingEntitiesError(false);
    } catch (error) {
      setLoadingEntities(false);
      setLoadingEntitiesError(true);
      console.log(`An error occured when trying to get entity data`);
      console.log(error);
    }
  };

  /**
   * Handle folder selection with no file handle approach:
   * We'll just store each File in memory and parse CSV if found.
   */
  const handleFileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    try {
      setCsvData(null);
      setMergedFiles([]);
      setFileIndex(0);

      const files = Array.from(e.target.files);
      let csvFile: File | null = null;
      const allMp3OrTxt: File[] = [];

      // Identify the single CSV (if any) and all .txt/.mp3
      for (const f of files) {
        const lowerName = f.name.toLowerCase();
        if (lowerName.endsWith('.csv') && !csvFile) {
          csvFile = f;
        } else if (lowerName.endsWith('.txt') || lowerName.endsWith('.mp3')) {
          allMp3OrTxt.push(f);
        }
      }

      // Parse CSV if we found one
      let csvMap = new Map<string, CSVMetadata>();
      if (csvFile) {
        const csvText = await csvFile.text();
        const parsedResult = await new Promise<any>((resolve, reject) => {
          Papa.parse(csvText, {
            complete: (results) => resolve(results.data),
            error: (error: any) => reject(error),
            skipEmptyLines: true,
          });
        });

        const csvRows: CSVMetadata[] = (parsedResult as string[][]).map(
          (row) => {
            const [fileName = '', title = '', url = '', embedLink = ''] = row;
            return {
              fileName,
              title: title || fileName,
              url: url || null,
              embedLink: embedLink || null,
            };
          }
        );
        setCsvData(csvRows);
        csvRows.forEach((item) => csvMap.set(item.fileName, item));
      }

      // Now build mergedFiles array
      const newMerged: FileData[] = allMp3OrTxt.map((f) => {
        const baseName = f.name.replace(/\.[^/.]+$/, '');
        const row = csvMap.get(baseName);
        return {
          file: f,
          name: f.name,
          title: row?.title || baseName,
          url: row?.url || null,
          embedLink: row?.embedLink || null,
          networkResult: null,
          loading: false,
        };
      });

      setMergedFiles(newMerged);
    } catch (error) {
      console.log('Error selecting folder:', error);
    }
  };

  /**
   * Upload transcript logic
   */
  const uploadTranscript = async (fileData: FileData, index: number) => {
    const updated = [...mergedFiles];
    updated[index] = { ...updated[index], loading: true };
    setMergedFiles(updated);
    let transcriptContent = '';
    try {
      // read text from the File
      transcriptContent = await fileData.file.text();
    } catch (err) {
      console.log('Error reading transcript file:', err);
      updated[index] = {
        ...updated[index],
        loading: false,
        networkResult: 'Unable to read transcript',
      };
      setMergedFiles(updated);
      return;
    }

    if (!transcriptContent) {
      updated[index] = {
        ...updated[index],
        loading: false,
        networkResult: 'No transcript content found',
      };
      setMergedFiles(updated);
      return;
    }

    const jsonData = JSON.stringify({
      url: fileData.url,
      title: fileData.title,
      embedLink: fileData.embedLink,
      transcript: transcriptContent,
      entityId: selectedEntity?.entityId,
    });

    let statusCode: number | null = null;
    try {
      const response = await fetch('/admin/upload/transcript', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: jsonData,
      });
      statusCode = response.status;

      if (!response.ok) {
        if (response.status === 401) {
          setRefreshToken(true);
        }
        throw new Error(`Transcript upload failed with ${response.status}`);
      }
    } catch (error: any) {
      console.log('Upload transcript error:', error.message);
      updated[index] = {
        ...updated[index],
        loading: false,
        networkResult: statusCode?.toString() ?? 'Error uploading transcript',
      };
      setMergedFiles(updated);
      return;
    }

    // success
    updated[index] = {
      ...updated[index],
      loading: false,
      networkResult: '200',
    };
    setMergedFiles(updated);
  };

  /**
   * Upload audio logic
   */
  const uploadAudio = async (fileData: FileData, index: number) => {
    if (!selectedEntity) {
      console.warn('No entity selected, skipping audio upload');
      return;
    }

    const updated = [...mergedFiles];
    updated[index] = { ...updated[index], loading: true };
    setMergedFiles(updated);

    let statusCode: number | null = null;
    let presignedUrlResponse: PresignedUrlResponse | null = null;
    let success = false;

    try {
      const audioFile = fileData.file;
      const fileType = audioFile.type || 'audio/mpeg';

      // Step 1: request presigned URL
      presignedUrlResponse = await handleRequestUploadUrl(
        fileData.url,
        fileData.title,
        fileData.embedLink,
        selectedEntity.entityId,
        fileType
      );

      // Step 2: upload
      success = await handleUploadAudioToUrl(
        presignedUrlResponse.presignedUrl,
        audioFile
      );
      statusCode = success ? 200 : 500;

      // Step 3: notify
      await notifyBackend(success, presignedUrlResponse.jobId);
    } catch (error: any) {
      console.log('Audio upload error:', error.message);
      updated[index] = {
        ...updated[index],
        loading: false,
        networkResult: statusCode?.toString() ?? 'Error when uploading audio',
      };
      setMergedFiles(updated);
      return;
    }

    // success or fail
    updated[index] = {
      ...updated[index],
      loading: false,
      networkResult: success ? '200' : '500',
    };
    setMergedFiles(updated);
  };

  /**
   * fetch presigned URL
   */
  const handleRequestUploadUrl = async (
    url: string | null,
    title: string,
    embedLink: string | null,
    entityId: string,
    fileType: string
  ): Promise<PresignedUrlResponse> => {
    const jsonData = JSON.stringify({
      url: url,
      title: title,
      embedLink: embedLink,
      entityId: entityId,
      fileType: fileType,
    });
    const endpointUrl = '/admin/upload/audio/url';
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: jsonData,
    });
    if (!response.ok) {
      if (response.status === 401) {
        setRefreshToken(true);
      }
      throw Error(`Unexpected error, status ${response.status}`);
    }
    const result = JSON.parse(await response.json());
    const jobId = Number(result.jobId);
    const presignedUrl = result.presignedUrl;
    return { presignedUrl, jobId };
  };

  /**
   * do PUT to presigned URL
   */
  const handleUploadAudioToUrl = async (
    endpointUrl: string,
    audioFile: File
  ) => {
    try {
      const uploadResponse = await fetch(endpointUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': audioFile.type,
        },
        body: audioFile,
      });
      return uploadResponse.ok;
    } catch (error) {
      console.log(`Error occured when trying to upload file to AWS:`);
      console.log(error);
      return false;
    }
  };

  /**
   * notify backend
   */
  const notifyBackend = async (success: boolean, jobId: number) => {
    const endpointUrl = '/admin/upload/audio/validate';
    const jsonData = JSON.stringify({
      success: success,
      jobId: jobId,
    });
    const response = await fetch(endpointUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: jsonData,
    });

    if (success && response.status === 404) {
      throw Error('File not found post-upload');
    }
    if (success && response.status === 200) return;
    if (!success && response.status === 200) {
      throw Error('We were unable to upload the file!');
    }
    if (response.status === 500) {
      throw Error('Server error');
    }
    throw Error(`Unexpected response code ${response.status}.`);
  };

  const startBulkUpload = () => {
    // Reset to first file
    setFileIndex(0);
    // Turn on the "uploading" flag
    setUploading(true);
  };

  useEffect(() => {
    // Only run if we are in "uploading" mode
    if (!uploading) return;

    // If we've processed all files, we're done
    if (fileIndex >= mergedFiles.length) {
      setUploading(false);
      return;
    }

    // Grab the current file
    const currentFile = mergedFiles[fileIndex];
    const lowerName = currentFile.name.toLowerCase();

    const doUpload = async () => {
      // 1. Decide which upload function
      if (lowerName.endsWith('.txt')) {
        await uploadTranscript(currentFile, fileIndex);
      } else if (lowerName.endsWith('.mp3')) {
        await uploadAudio(currentFile, fileIndex);
      }
      // 2. Move on to the next file
      setFileIndex((prev) => prev + 1);
    };

    doUpload();
    // Include fileIndex in dependencies so effect re-runs for the next file
  }, [uploading, fileIndex]);

  // Attempt to fetch entity data if not loaded
  useEffect(() => {
    if (!loadingEntities && !loadingEntitiesError && entityInfo.length === 0) {
      requestEntityData();
    }
  }, [loadingEntitiesError]);

  // -- LargeFormPanel Logic (re-login)
  const handleCloseFormPressed = () => setRefreshToken(false);

  const handleUserOpenedSignIn = () => {
    window.open('/signin', '_blank');
  };

  const handleCheckUpdatedToken = () => {
    const newTok = localStorage.getItem('token');
    if (newTok !== token) {
      setToken(newTok);
      setRefreshToken(false);
    }
  };

  let mainPageContent: JSX.Element;

  if (loadingEntities) {
    mainPageContent = <LoadingSpinner />;
  } else {
    mainPageContent = (
      <div>
        {refreshToken && (
          <LargeFormPanel
            title="Session Expired"
            handleCloseFormPressed={handleCloseFormPressed}
            formChildren={
              <div>
                <p>You must re-log in. Click below to open the sign-in page.</p>
                <button onClick={handleUserOpenedSignIn}>Open Sign-In</button>
                <button onClick={handleCheckUpdatedToken}>
                  I've Logged In - Refresh Token
                </button>
              </div>
            }
          />
        )}

        <h2>Bulk Upload</h2>

        <input
          type="file"
          multiple
          onChange={handleFileSelect}
          ref={(node) => {
            if (node) {
              (
                node as HTMLInputElement & { webkitdirectory?: boolean }
              ).webkitdirectory = true;
              (node as HTMLInputElement & { directory?: boolean }).directory =
                true;
            }
          }}
          className="folder-selector"
        />

        <button onClick={startBulkUpload} disabled={mergedFiles.length === 0}>
          Bulk Upload
        </button>

        <hr />

        {/* Show each file's status */}
        {mergedFiles.map((item, index) => (
          <div key={index} className="BulkUploadFileInfoContainer">
            <div>
              <strong>File:</strong> {item.name}
              <br />
            </div>
            <div>
              <strong>Title:</strong> {item.title || 'No title provided'}
              <br />
            </div>
            <div>
              <strong>URL:</strong> {item.url || 'No URL provided'}
              <br />
            </div>
            <div>
              <strong>Embed link:</strong>{' '}
              {item.embedLink || 'No embed provided'}
              <br />
            </div>
            <div>
              <strong>Loading:</strong> {item.loading ? 'Yes' : 'No'}
              <br />
            </div>
            <div>
              <strong>Network Result:</strong> {item.networkResult ?? 'None'}
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <SidePanelScaffold
      stickyTopBar={true}
      isExpanded={isExpanded}
      showCollapseIcons={true}
      onExpandedToggle={handleExpandToggle}
      mainPageChildren={mainPageContent}
      sideBarChildren={<AdminSideBarContent nav={nav} />}
      topBarChildren={
        <>
          <MainLogo />
          <div />
        </>
      }
    ></SidePanelScaffold>
  );
}

export default BulkUploadPage;
