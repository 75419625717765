import './HoveringCard.css';

interface Props {
  isRounded: boolean;
  isBordered: boolean;
  children: React.ReactNode;
}

function HoveringCard({ isRounded, isBordered, children }: Props): JSX.Element {
  return (
    <div
      className={`HoveringCardContainer ${isRounded && 'HoveringCardRounded'} ${isBordered && `HoveringCardBordered`}`}
    >
      {children}
    </div>
  );
}

export default HoveringCard;
