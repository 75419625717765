import './MainLogo.css';

function MainLogo(): JSX.Element {
  return (
    <div className="LogoContainer">
      <img src={'/video-search-logo.png'} alt="Logo" className="LogoImage" />
      <h3>Video Search AI</h3>
    </div>
  );
}

export default MainLogo;
