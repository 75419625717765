import React from 'react';
import GenericTopBar from '../GenericTopBar';
import MainLogo from '../../../components/MainLogo';
import { useLocation, useNavigate } from 'react-router-dom';
import './MainTopBar.css';
import { FaUser } from 'react-icons/fa';

interface Props {
  isSticky: boolean;
  showAdminButton: boolean;
  additionalLeftChildren?: React.ReactNode;
  additionalMiddleChildren?: React.ReactNode;
  additionalRightChildren?: React.ReactNode;
}

function MainTopBar({
  isSticky,
  showAdminButton,
  additionalLeftChildren,
  additionalMiddleChildren,
  additionalRightChildren,
}: Props): JSX.Element {
  const nav = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const homePagePath = '/';
  const chatPagePath = '/chat';
  const aboutUsPagePath = '/aboutus';
  const adminPagePath = '/admin';
  return (
    <GenericTopBar
      sticky={isSticky}
      leftChildren={
        <div className="MainTopBarLogoRow">
          {additionalLeftChildren}
          <MainLogo />
        </div>
      }
      middleChildren={
        <>
          {additionalMiddleChildren}
          <button
            className={`MainTopBarButton ${pathName == homePagePath && 'MainTopBarHighlightButton'}`}
            onClick={() => {
              nav(`${homePagePath}`);
            }}
          >
            Home
          </button>
          <button
            className={`MainTopBarButton ${pathName == chatPagePath && 'MainTopBarHighlightButton'}`}
            onClick={() => {
              nav(`${chatPagePath}`);
            }}
          >
            Chat
          </button>
          <button
            className={`MainTopBarButton ${pathName == aboutUsPagePath && 'MainTopBarHighlightButton'}`}
            onClick={() => {
              nav(`${aboutUsPagePath}`);
            }}
          >
            About Us
          </button>
          {showAdminButton && (
            <button
              className={`MainTopBarButton ${pathName == adminPagePath && 'MainTopBarHighlightButton'}`}
              onClick={() => {
                nav(`${adminPagePath}`);
              }}
            >
              Admin
            </button>
          )}
        </>
      }
      rightChildren={
        <div className="MainTopBarUserButtonRow">
          {additionalRightChildren}
          <button
            className="MainTopBarLoginButton"
            onClick={() => {
              nav('/signin');
            }}
          >
            <FaUser />
            Sign In
          </button>
          <button
            className="MainTopBarSignUpButton"
            onClick={() => {
              nav('/signup');
            }}
          >
            Sign Up
          </button>
        </div>
      }
    />
  );
}

export default MainTopBar;
