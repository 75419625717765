import React from 'react';
import './Home.page.css';
import { useNavigate } from 'react-router-dom';
import MainTopBar from '../../ui/panels/TopBars/MainTopBar';
import HoveringCard from '../../ui/components/HoveringCard';
import PrimaryFooter from '../../ui/components/PrimaryFooter';
import heroImage from '../../images/illustration-grp.png';
import capsule from '../../images/redblue-capsule.png';
import trail from '../../images/wave-trail.png';
import question from '../../images/question.png';
import idea from '../../images/idea.png';
import files from '../../images/files.png';
import search from '../../images/search.png';

function HomePage() {
  const nav = useNavigate();

  return (
    <div className="HomePageContainer">
      <div className="HomePageSplashGradient one"></div>
      <div className="HomePageSplashGradient two"></div>
      <MainTopBar isSticky={true} showAdminButton={false} />
      <div className="HomePageContentContainer">
        <div className="HomePageHeroRow">
          <div className="HomePageHeroSpacer" />
          <div className="HomePageHeroSpacer" />
          <div className="HomePageHeroTextColumn">
            <span className="HomePageHeroSectionText">
              We Back You With A Smile Forever
            </span>
            <p className="HomePageHeroParagraphText">
              Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem
              ipsum dolor. Lorem ipsum dolor. Lorem ipsum dolor. Lorem ipsum
              dolor.
            </p>
            <button
              className="HomePageHeroButton"
              onClick={() => {
                nav('/chat');
              }}
            >
              Start Chat
            </button>
          </div>
          <div className="HomePageHeroImageColumn">
            <div className="HomePageHeroImageTextOneContainer">
              <p className="HomePageHeroImageText">
                Do you have any studies on vitamin D?
              </p>
            </div>
            <div className="HomePageHeroImageBox" />
            <div className="HomePageHeroImageImageContainer" />
            <img
              src={heroImage}
              alt="Illustration"
              className="HomePageHeroImageImageContainer"
            />
            <div className="HomePageHeroImageTextTwoContainer">
              <p className="HomePageHeroImageText">
                Find for me some videos on Vitamin D and it's related effects.
              </p>
            </div>
            <img
              src={capsule}
              alt="Illustration"
              className="HomePageHeroImageCapsule"
            />
          </div>
          <div className="HomePageHeroSpacer" />
        </div>
        <span className="HomePageJoinSectionText">How it Works</span>
        <div className="HomePageHowItWorksRow">
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#FFDD00' }}
                >
                  <img
                    src={question}
                    alt="Illustration"
                    className="HomePageHowIconQuestion"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 1</div>
                <div className="HomePageHowDescriptionText">
                  Ask a question on a topic
                </div>
              </div>
            </div>
          </HoveringCard>
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#00F25C' }}
                >
                  <img
                    src={search}
                    alt="Illustration"
                    className="HomePageHowIconSearch"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 2</div>
                <div className="HomePageHowDescriptionText">
                  If we have information in the topic, the AI will find it.
                </div>
              </div>
            </div>
          </HoveringCard>
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#886DF0' }}
                >
                  <img
                    src={idea}
                    alt="Illustration"
                    className="HomePageHowIconIdea"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 3</div>
                <div className="HomePageHowDescriptionText">
                  It forms an informed answer and cites the sources for you.
                </div>
              </div>
            </div>
          </HoveringCard>
          <HoveringCard isRounded={true} isBordered={true}>
            <div className="HomePageHowItWorksCardContainer">
              <div className="HomePageCardAccentRow">
                <div
                  className="HomePageAngledSquareContainer"
                  style={{ backgroundColor: '#FF0000' }}
                >
                  <img
                    src={files}
                    alt="Illustration"
                    className="HomePageHowIconFiles"
                  />
                </div>
              </div>
              <div className="HomePageHowTextContainer">
                <div className="HomePageHowStepText">Step 4</div>
                <div className="HomePageHowDescriptionText">
                  It then provides the sources to you for further research.
                </div>
              </div>
            </div>
          </HoveringCard>
        </div>
        <div className="HomePageJoinRow">
          <div className="HomePageJoinText">Ready to get started?</div>
          <img src={trail} alt="Illustration" className="HomePageJoinTrail" />
          <button
            className="HomePageJoinButton"
            onClick={() => {
              nav('/signup');
            }}
          >
            Sign-up Now
          </button>
        </div>
        <PrimaryFooter />
      </div>
    </div>
  );
}

export default HomePage;
